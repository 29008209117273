import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import CookieService from 'services/cookieService/cookieService';
import GlobalErrorsService from 'services/globalErrorsService/globalErrorsService';
import LoginService from 'services/loginService/loginServise';
import PairingService from 'services/pairingService/pairingService';
import PreconditionService from 'services/preconditionService/preconditionService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { ActionForPost } from 'types/pairing';

import AuthorizationStore, {
  ApiTokenAuthResponse,
  LoginWithJWTRequest,
} from 'dataStore/stores/authorizationStore/authorizationStore';
import AxiosTransport from 'dataStore/transports/axiosTransport/axiosTransport';

import { calculateCookieExpiration } from 'utils/utils';

import { PENDING_VERIFICATION_STATUSES, countryAvailableCookieName, userCountryIdCookieName } from '../constants';
import PageController from '../pageController/pageController';
import { Routes } from '../routes';

export type Token = string | undefined;

export interface ApiLoginKeyResponse {
  id: string;
  name: string;
  token: string;
  expires_at: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface ILoginWithCredentials {
  email: string;
  password: string;
  actionForPost: ActionForPost | null;
  stopRedirect?: boolean;
  isConfirmPassword?: boolean;
}

export default class LoginController {
  @observable
  private _busy = false;

  @computed
  public get busy(): boolean {
    return this._busy;
  }

  public setBusy(busy: boolean): void {
    this._busy = busy;
  }

  @observable
  private _errorValue = 0;

  @computed
  public get errorValue(): number {
    return this._errorValue;
  }

  public constructor(
    private readonly transport: AxiosTransport,
    private readonly _authorizationStore: AuthorizationStore,
    private readonly _cookieService: CookieService,
    private readonly _userService: UserService,
    private readonly _pageController: PageController,
    private readonly _pairingService: PairingService,
    private readonly _loginService: LoginService,
    private readonly _preconditionService: PreconditionService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _globalErrorsService: GlobalErrorsService,
    private readonly _analyticService: AnalyticService,
  ) {
    makeObservable(this);
  }

  @observable
  private _actionForPost: ActionForPost | null = null;

  @observable
  private _postLoginActionExecuted = false;

  @computed get postLoginActionExecuted() {
    return this._postLoginActionExecuted;
  }

  @action setPostLoginActionExecuted(postLoginActionExecuted: boolean) {
    this._postLoginActionExecuted = postLoginActionExecuted;
  }

  @computed get actionForPost() {
    return this._actionForPost;
  }

  @action setActionForPost(actionForPost: ActionForPost | null) {
    this._actionForPost = actionForPost;
  }

  @computed
  public get isLoggedIn(): boolean {
    return this._authorizationStore.isLoggedIn;
  }

  public parseTokenToExpDate = (token: string) => {
    return this._loginService.parseTokenToExpDate(token);
  };

  @observable
  private _redirectUrl: string | null = null;

  @action
  public setRedirectUrl(url: string | null) {
    this._redirectUrl = url;
  }

  @computed
  public get redirectUrl() {
    return this._redirectUrl;
  }

  public updateJWTCookie(newToken: string): boolean {
    this._cookieService.removeCookie('JWT', {});
    this._authorizationStore.setToken(newToken);
    const now = new Date();
    const tokenDate = this.parseTokenToExpDate(newToken);
    if (tokenDate) {
      const hourDelta = Math.ceil(Math.abs(+tokenDate - +now) / 36e5);
      this._cookieService.setCookie('JWT', newToken, {
        path: '/',
        maxAge: hourDelta,
        maxAgeUnit: 'hours',
        secure: true,
      });
      return true;
    } else {
      return false;
    }
  }

  public async postLoginAction() {
    if (this._actionForPost) {
      await this._pairingService.tryLinkPairingCode(this._actionForPost);
      this.setPostLoginActionExecuted(true);
    }
  }

  public async handleLogin({
    token,
    isRefresh,
    promoCodeFromUrl,
    registerWithInviteToken,
    next,
  }: {
    token: string;
    isRefresh?: boolean;
    promoCodeFromUrl?: string;
    registerWithInviteToken?: boolean;
    next?: string;
  }) {
    const isInTVFlow = !!this._pairingService.getPairingCodeFromCookie();
    if (!token) return;
    this._cookieService.setCookie('cookiescriptaccept', 'visit', {
      path: '/',
      expires: calculateCookieExpiration(),
    });
    this._cookieService.removeCookie(countryAvailableCookieName, { path: '/' });
    this._cookieService.removeCookie(userCountryIdCookieName, { path: '/' });
    try {
      await this._authorizationStore.setToken(token);
      const isLoggedIn = await this.checkIsLoggedIn();
      if (isLoggedIn) {
        await this.updateJWTCookie(token);
        if (isRefresh) {
          return;
        }
        if (next) {
          return this._pageController.setLocation(next, true);
        }
        if (registerWithInviteToken) {
          const redirectRoute = await this._subscriptionService.goToEnrollment();
          this._pageController.setLocation(redirectRoute || Routes.RegisterSelectPlan);

          return;
        }
        if (promoCodeFromUrl) {
          const redirectRoute = await this._subscriptionService.goToEnrollment(promoCodeFromUrl);
          this._pageController.setLocation(redirectRoute || Routes.RegisterSelectPlan);

          return;
        }
        if (isInTVFlow && this._actionForPost) {
          await this.postLoginAction();
          return;
        }
        await this._userService.fetchUser();
        if (
          !isInTVFlow &&
          !this.postLoginActionExecuted &&
          PENDING_VERIFICATION_STATUSES.includes(this._userService.verificationStatus)
        ) {
          this.setPostLoginActionExecuted(true);
          this._pageController.setLocation(Routes.Verify);
        }
      }
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async loginWithCredentials({
    email,
    password,
    actionForPost,
    stopRedirect = false,
    isConfirmPassword = false,
  }: ILoginWithCredentials): Promise<void> {
    this.setBusy(true);
    const isInTVFlow = !!this._pairingService.getPairingCodeFromCookie();

    try {
      const postData: LoginRequest = { email, password };

      const apiLoginKeyResponse = await this.transport.post<ApiLoginKeyResponse, LoginRequest>(
        '/api-token-auth',
        postData,
      );
      this.setActionForPost(actionForPost);
      if (apiLoginKeyResponse?.data.token) {
        await this.handleLogin({ token: apiLoginKeyResponse.data.token });
        if (actionForPost === 'LOG_IN' && !isConfirmPassword) {
          this._analyticService.handleLogin({ ssoProviders: [], isInTVFlow });
        }
        if (actionForPost === 'SIGN_UP') {
          this._analyticService.handleRegistration({ ssoTypes: [], isInTVFlow });
        }
        if (
          actionForPost === 'LOG_IN' &&
          !this._postLoginActionExecuted &&
          this._authorizationStore.isLoggedIn &&
          !isInTVFlow &&
          !stopRedirect
        ) {
          const nextPathHash = this._pageController.getHash().split('=');
          const nextPath = nextPathHash?.includes('#?next') ? decodeURIComponent(nextPathHash[1]) : Routes.Home;

          this._pageController.setLocation(this.redirectUrl ?? nextPath);
        }
      }
    } finally {
      this.setBusy(false);
      this.setPostLoginActionExecuted(false);
      this.setRedirectUrl(null);
    }
  }

  public async loginWithJWT(isRefresh?: boolean, next?: string): Promise<void> {
    const jwtToken = this._authorizationStore.token || (this._cookieService.getCookie('JWT') as string);
    const refreshTokenResponse = await this.transport.post<ApiTokenAuthResponse, LoginWithJWTRequest>(
      '/api-token-refresh',
      {
        token: jwtToken,
      },
    );

    if (refreshTokenResponse?.data.token) {
      await this.handleLogin({ token: refreshTokenResponse.data.token, isRefresh: isRefresh, next });
    }
  }

  public getTokenData(token: string) {
    return this._loginService.getTokenData(token);
  }

  public async check(): Promise<void> {
    await this._loginService.check();
  }

  public async checkIsLoggedIn(): Promise<boolean> {
    return await this._loginService.checkIsLoggedIn();
  }

  public async logout(withoutLogOutAction?: boolean): Promise<void> {
    this._preconditionService.clearPreconditions();
    this._userService.clearStore();
    this.setActionForPost(null);
    this._globalErrorsService.setUserUnderEighteenError('');
    await this._loginService.logout(withoutLogOutAction);
  }

  public checkIsFromRedeemPage = () => {
    return this._pageController.checkIsFromRedeemPage();
  };
}
