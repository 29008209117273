import ChangePasswordModal from 'app/modals/changePasswordModal/changePasswordModal';
import clsx from 'clsx';
import { devices } from 'lists/devices';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { ISubscriptionByUsableSubscription, SubscriptionsTrialPeriod } from 'types/subscriptions';

import { formatPriceToAmountInCents } from 'utils/textFormat';
import { translateErrors } from 'utils/translateErrors';

import { Routes } from 'app/global/routes';

import { SelectPlanControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import { IconChecked, IconCloud, IconFamily, IconRocket, IconUnChecked, IconUser } from 'app/components/icons';
import Modal from 'app/components/modal/modal';
import Spinner from 'app/components/spinner/spinner';

import DeleteAccount from '../deleteAccount/deleteAccount';

import { errorHandler } from './errorHandler';

import styles from './selectPlanForm.module.scss';

interface ISelectPlanForm {
  toggleModal?: () => void;
  isSubscriptionPage?: boolean;
}

const SelectPlanForm: React.FC<ISelectPlanForm> = observer(({ toggleModal, isSubscriptionPage = false }) => {
  const selectPlanController = useContext(SelectPlanControllerContext);
  const [isFetching, setIsFetching] = useState(false);
  const [errorAfterRequest, setErrorAfterRequest] = useState('');
  const navigate = useNavigate();
  const {
    user,
    isCloudRegistrationAllowed,
    isApiHasCloudSupport,
    isInSupportedCloudCountry,
    isInTVFlow,
    isCloudUser,
    assumedCountry,
    userCountry,
    subscriptionPlans,
    currentSubscriptionToRender,
    selectedPlan,
    errorOverrides,
    error,
    setErrorRequest,
    toggleModalReauth,
    reauthModalIsOpen,
    reauthModaContent,
    ensureProductInCart,
    isLoading,
    toggleAllPlans,
    showRecommendedOnly,
  } = selectPlanController;

  const handleOpenCountryPopup = (e: React.MouseEvent) => {
    e.preventDefault();
    toggleModal && toggleModal();
  };

  const initPage = async () => {
    try {
      await selectPlanController.initForm();
    } catch (e: any) {
      setErrorRequest(e);
    }
  };

  useEffect(() => {
    initPage();
  }, [user.deletion_date]);

  const renderErrors = () => {
    const errorSupport = errorHandler({
      apiHasCloudSupport: isApiHasCloudSupport,
      cloudRegistrationsBlocked: !isCloudRegistrationAllowed,
      isInSupportedCloudCountry,
    });
    if (!errorSupport || isLoading) return null;
    return (
      <Alert className={clsx('alert-without-icon', styles['alert-error-support'])} danger>
        <span dangerouslySetInnerHTML={{ __html: errorSupport }} />
      </Alert>
    );
  };

  const handleSelectPlan = (plan: ISubscriptionByUsableSubscription) => {
    if (plan.id === currentSubscriptionToRender?.id) return;
    selectPlanController.setSelectedPlan(plan);
  };

  const continueTvFlow = () => {
    navigate(Routes.SuccessTv);
  };

  const handleContinue = async () => {
    setIsFetching(true);
    try {
      await selectPlanController.confirmSelectedPlan();
    } catch (e: any) {
      setErrorAfterRequest(translateErrors(error || e.message));
    } finally {
      setIsFetching(false);
    }
  };

  const handleRenderIcon = (plan: ISubscriptionByUsableSubscription) => {
    if (!plan.add_ons.length && plan.type === 'SF') {
      return <IconFamily />;
    } else if (plan.add_ons.length && plan.type === 'SF') {
      return <IconRocket />;
    } else if (plan.add_ons.length && plan.type === 'SP') {
      return <IconCloud />;
    } else {
      return <IconUser />;
    }
  };

  const handleRenderBestChoice = (plan: ISubscriptionByUsableSubscription) => {
    if (!plan.add_ons.length && plan.type === 'SF') {
      return 'Best for families';
    } else if (plan.add_ons.length && plan.type === 'SF') {
      return 'You got it all!';
    } else if (plan.add_ons.length && plan.type === 'SP') {
      return 'Play anywhere';
    } else {
      return 'Best value';
    }
  };

  const handleDevicesDescriptionRender = (deviceId: string) => {
    switch (deviceId) {
      case 'device-windows':
        return (
          <>
            <p>PC Windows 10</p>
            <p>or higher</p>
          </>
        );
      case 'device-samsung':
        return (
          <>
            <p>Samsung Smart TVs</p>
            <p>2020 or newer models</p>
          </>
        );
      case 'device-lg':
        return (
          <>
            <p>LG Smart TVs 2021</p>
            <p>or newer models</p>
          </>
        );
      default:
        return (
          <>
            <p>Mobile devices with</p>
            <p>Android 9.0 and up</p>
          </>
        );
    }
  };

  if (isLoading) {
    return <Spinner>Fetching details</Spinner>;
  }

  if (user.deletion_date) {
    return <DeleteAccount />;
  }

  const errorToRender =
    error ||
    errorOverrides.ACCOUNT_HAS_NON_RECURRING_SUBSCRIPTION ||
    errorOverrides.CANNOT_CHANGE_TO_OR_FROM_FREE_PLAN ||
    errorAfterRequest;
  if (errorToRender) {
    return (
      <>
        <Alert danger>{<span dangerouslySetInnerHTML={{ __html: translateErrors(errorToRender) }} />}</Alert>
        {isInTVFlow && (
          <div className={styles['select-plan-isInTv-button']}>
            <Button className="btn btn-default" onClick={continueTvFlow}>
              Log in without a cloud subscription
            </Button>
          </div>
        )}
      </>
    );
  }
  return (
    <div className={styles['select-plan-form']}>
      <div>
        <div className={styles['select-plan-form-includes']}>
          All plans include:{' '}
          <div className={styles['select-plan-form-includes-items']}>
            <div>
              <i className="fa-solid fa-clock"></i>
              <span>Unlimited game time</span>
            </div>
            <div>
              <i className="fa-solid fa-wallet"></i>
              <span>Enjoy a free trial!</span>
            </div>
            <div>
              <i className="fa-solid fa-gamepad"></i>
              <span>
                Access to <strong>1400+ games</strong> on PC!
              </span>
            </div>
          </div>
        </div>
        {renderErrors()}
      </div>

      <div className={styles['select-plan-card']}>
        {subscriptionPlans
          ?.filter((i) => i._isRecommended || !showRecommendedOnly)
          .map((plan) => (
            <div
              key={plan.id}
              className={clsx(
                styles['select-plan-card-item'],
                selectedPlan?.id === plan.id && styles['select-plan-card-item-selected'],
                currentSubscriptionToRender?.id === plan.id && styles['select-plan-card-item-current'],
              )}
              onClick={() => handleSelectPlan(plan)}
            >
              <div className={styles['select-plan-card-item-header']}>
                <span
                  className={clsx(
                    styles['select-plan-card-item-header-tile'],
                    styles['select-plan-card-item-header-tile__selected'],
                  )}
                >
                  {handleRenderIcon(plan)}
                </span>
                <div className={styles['select-plan-card-item-header-wrapper']}>
                  <h3 className={styles['select-plan-card-item-header-title']}>{plan.name}</h3>
                  <div className={styles['select-plan-card-item-header-price']}>
                    <div>
                      <span className={styles['select-plan-card-item-header-price-total']}>
                        {formatPriceToAmountInCents(plan._totalPricing, 'amount_in_cents')}
                      </span>
                      /{SubscriptionsTrialPeriod[plan.period]}
                    </div>
                    <span>{handleRenderBestChoice(plan)}</span>
                  </div>
                </div>
              </div>
              <div className={styles['select-plan-card-item-options']}>
                <div
                  className={clsx(
                    styles['select-plan-card-item-options-item'],
                    styles['select-plan-card-item-options-item-checked'],
                  )}
                >
                  <IconChecked />
                  {plan.max_members} {`${plan.max_members > 1 ? 'Users' : 'User'}`}
                </div>
                <div
                  className={clsx(
                    styles['select-plan-card-item-options-item'],
                    styles['select-plan-card-item-options-item-checked'],
                  )}
                >
                  <IconChecked />
                  Play on PC
                </div>
                <div
                  className={clsx(
                    styles['select-plan-card-item-options-item'],
                    plan._isCloudSubscription && styles['select-plan-card-item-options-item-checked'],
                  )}
                >
                  {plan._isCloudSubscription ? (
                    <IconChecked />
                  ) : (
                    <span className={styles['select-plan-card-checkbox-unchecked']}>
                      <IconUnChecked />
                    </span>
                  )}
                  Play on TV
                </div>
                <div
                  className={clsx(
                    styles['select-plan-card-item-options-item'],
                    plan._isCloudSubscription && styles['select-plan-card-item-options-item-checked'],
                  )}
                >
                  {plan._isCloudSubscription ? (
                    <IconChecked />
                  ) : (
                    <span className={styles['select-plan-card-checkbox-unchecked']}>
                      <IconUnChecked />
                    </span>
                  )}
                  Play on Mobile devices
                </div>
              </div>
              <div className={clsx(styles['select-plan-card-item-button'])}>
                <Button disabled={currentSubscriptionToRender?.id === plan.id} onClick={() => handleSelectPlan(plan)}>
                  {currentSubscriptionToRender?.id === plan.id ? 'Current plan' : 'Select'}
                </Button>
              </div>
            </div>
          ))}
      </div>

      <button onClick={toggleAllPlans} className={styles['see-all-plans-button']}>
        {showRecommendedOnly ? 'See all plans' : 'Hide'}
      </button>

      <div className={styles['select-plan-devices']}>
        <div>
          <p className={styles['select-plan-devices-following']}>* Utomik runs on the following devices:</p>
          <div className={styles['select-plan-devices-wrapper']}>
            {devices.map((device) => (
              <div key={device.id} className={styles['select-plan-devices-item']}>
                <div className={styles['select-plan-devices-item-img-wrapper']}>
                  <div
                    className={clsx(
                      styles['select-plan-devices-item-img'],
                      styles[`select-plan-devices-item-img-${device.id}`],
                    )}
                  >
                    <img
                      src={`${device.id === 'device-windows' ? device.img.replace('.png', '-sp.png') : device.img}`}
                      alt={device.id}
                    />
                  </div>
                </div>

                {handleDevicesDescriptionRender(device.id)}
              </div>
            ))}
          </div>
          <p className={clsx('small', styles['select-plan-devices-small'])}>
            <a href="https://support.utomik.com/hc/en-us/articles/5205109982108" target="_blank" rel="noreferrer">
              See all devices with Utomik Cloud support
            </a>
          </p>
        </div>
        <Button
          lg
          primary
          onClick={handleContinue}
          loading={isFetching}
          disabled={
            !selectedPlan ||
            isFetching ||
            !userCountry.is_supported ||
            (!!assumedCountry && !assumedCountry?.is_supported)
          }
          className={!isSubscriptionPage && styles['button-continue-sticky-hide']}
        >
          Continue
        </Button>
      </div>

      {isInTVFlow && !isCloudUser && (
        <div className={styles['select-plan-isInTv-button']}>
          <Button className="btn btn-default" onClick={continueTvFlow}>
            Log in without a cloud subscription
          </Button>
        </div>
      )}

      <div className={styles['select-plan-footer']}>
        {assumedCountry && (
          <div className={styles['select-plan-footer-text']}>
            We have registered {assumedCountry.name || userCountry.name} as your country of residence.
            <Button onClick={handleOpenCountryPopup} link>
              Click here if this is incorrect.
            </Button>
          </div>
        )}
        {!userCountry.is_supported && (
          <div>
            <div>This account is registered in {assumedCountry?.name || user.country?.name}.</div>
            <div className="text-danger">Unfortunately, Utomik is not supported in your country.</div>
          </div>
        )}
      </div>
      <Modal show={reauthModalIsOpen} onHide={toggleModalReauth} title={reauthModaContent?.title}>
        <ChangePasswordModal
          closeModal={toggleModalReauth}
          subtitle={reauthModaContent?.message}
          placeholder="Password"
          danger={reauthModaContent?.buttonIsDanger}
          textButtonNext={reauthModaContent?.buttonLabel}
          closeModalAfterConfirm={true}
          completeConfirm={ensureProductInCart}
        />
      </Modal>
      {!isSubscriptionPage && (
        <div className={styles['button-continue-sticky']}>
          <Button
            lg
            primary
            onClick={handleContinue}
            loading={isFetching}
            disabled={
              !selectedPlan ||
              isFetching ||
              !userCountry.is_supported ||
              (!!assumedCountry && !assumedCountry?.is_supported)
            }
          >
            Continue
          </Button>
        </div>
      )}
    </div>
  );
});
export default SelectPlanForm;
