import {
  CardCvvElement,
  CardMonthElement,
  CardNumberElement,
  CardNumberElementChangeEvent,
  CardYearElement,
  IndividualElementChangeEvent,
  useRecurly,
} from '@recurly/react-recurly';
import { recurlyCreditCardSchema } from 'app/validationSchemes/recurlyCreditCardSchema';
import clsx from 'clsx';
import { FormikProvider, useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { IExtraData, IPaymentType } from 'types/billing';
import { Messages } from 'types/messages';

import { transformCountriesRangeWIthAlpha, transformCountryToCountryAlpha } from 'utils/transformCountries';
import { statesRange } from 'utils/transformDataToRange';

import { UserControllerContext } from 'app/context/storesContext';

import Alert from 'app/components/alert/alert';
import Button from 'app/components/button/button';
import Input from 'app/components/input/input';
import Select from 'app/components/select/select';
import Spinner from 'app/components/spinner/spinner';

import SuccessAddCard from '../successAddCard/successAddCard';

import useRecurlyCreditCard from './useRecurlyCreditCard';

import styles from './recurlyCreditCard.module.scss';

export type RecurlyCardFormValues = {
  cardNumber: string;
  month: string;
  year: string;
  expirationDate: string;
  cvc: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  state: string;
};

type RecurlyCardFormErrors = {
  cardNumber: string | undefined;
  month: string | undefined;
  year: string | undefined;
  cvc: string | undefined;
};

interface IRecurlyCreditCard {
  handleSubmitCard: (extraData: IExtraData, recurlyToken: string, paymentType: IPaymentType) => void;
  handleBack: () => void;
  isCheckoutPage?: boolean;
}

const RecurlyCreditCard: React.FC<IRecurlyCreditCard> = observer(
  ({ handleSubmitCard, handleBack, isCheckoutPage = false }) => {
    const userController = useContext(UserControllerContext);
    const [isLoading, setIsLoading] = useState(true);
    const [errorRequest, setErrorRequest] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const { user, allCountries } = userController;
    const formRef = React.useRef<HTMLFormElement | null>(null);
    const recurly = useRecurly();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { isCountryWithKnownStates, countriesAlpha } = useRecurlyCreditCard();

    const emptyOptions = {
      label: '',
      value: '',
    };
    const initialValues = {
      cardNumber: '',
      month: '',
      year: '',
      expirationDate: '',
      cvc: '',
      firstName: user.first_name || '',
      lastName: user.last_name || '',
      address: '',
      postalCode: '',
      city: '',
      country: user.country
        ? transformCountryToCountryAlpha({ allCountries: allCountries, countryId: `${user.country?.id}` })
        : '',
      state: '',
    };

    const updateBillingInfo = async (recurlyToken: string, recaptchaToken: string) => {
      const extraData = {
        address: formik.values.address,
        zip: formik.values.postalCode,
        country: formik.values.country,
        g_recaptcha_response: recaptchaToken,
      };
      setIsFetching(true);

      await handleSubmitCard(extraData, recurlyToken, 'creditcard');
    };

    const onSubmitForm = async () => {
      window.scrollTo(0, 0);

      setIsFetching(true);
      if (formRef.current) {
        await recurly.token(formRef.current, (err, token) => {
          if (err) {
            const { fields, message } = err;
            setIsFetching(false);
            if (fields && fields.includes('postal_code')) {
              formik.setFieldError('postalCode', message);
              return;
            }
            setErrorRequest('Cannot finalize payment; missing payment token.');
          } else {
            if (!executeRecaptcha) {
              setErrorRequest(Messages.RECAPTCHA_FAILED);
              return;
            }
            executeRecaptcha('enquiryFormSubmit')
              .then((gReCaptchaToken) => {
                updateBillingInfo(token.id, gReCaptchaToken);
              })
              .catch(() => {
                setErrorRequest(Messages.RECAPTCHA_FAILED);
              });
          }
        });
      }
    };

    const handleValidateErrors = (values: RecurlyCardFormValues) => {
      const errors = {} as RecurlyCardFormErrors;
      if (formik.errors.cardNumber) {
        errors.cardNumber = formik.errors.cardNumber;
      }
      if (formik.errors.year) {
        errors.year = formik.errors.year;
      }
      if (formik.errors.month) {
        errors.month = formik.errors.month;
      }
      if (formik.errors.cvc) {
        errors.cvc = formik.errors.cvc;
      }

      return errors;
    };

    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: recurlyCreditCardSchema,
      onSubmit: onSubmitForm,
      enableReinitialize: true,
      validateOnBlur: false,
      validateOnChange: true,
      validate: handleValidateErrors,
    });

    useEffect(() => {
      const isState = isCountryWithKnownStates(formik.values.country);
      if (!isState) {
        formik.setFieldValue('state', '');
      }
    }, [formik.values.country]);

    const handleReady = () => {
      setIsLoading(false);
    };

    const handleChangeNumber = (change: CardNumberElementChangeEvent) => {
      if (!formik.touched.cardNumber && change.length) {
        formik.setFieldTouched('cardNumber', true);
      }

      if (!change.valid) {
        formik.setFieldError('cardNumber', 'Invalid credit card number');
        return;
      }
      formik.setFieldError('cardNumber', undefined);
    };
    const handleChangeMonth = (change: IndividualElementChangeEvent) => {
      if (!formik.touched.month && change.length) {
        formik.setFieldTouched('month', true);
      }
      if (!change.valid) {
        formik.setFieldError('month', 'Invalid expiration date');
        return;
      }
      formik.setFieldError('month', undefined);
    };
    const handleChangeYear = (change: IndividualElementChangeEvent) => {
      if (!formik.touched.year && change.length) {
        formik.setFieldTouched('year', true);
      }
      if (!change.valid) {
        formik.setFieldError('year', 'Invalid expiration date');
        return;
      }
      formik.setFieldError('year', undefined);
    };
    const handleChangeCVC = (change: IndividualElementChangeEvent) => {
      if (!formik.touched.cvc && change.length) {
        formik.setFieldTouched('cvc', true);
      }
      if (!change.valid) {
        formik.setFieldError('cvc', 'Invalid CVC code');
        return;
      }
      formik.setFieldError('cvc', undefined);
    };

    const handleChangeFormik = (e: React.ChangeEvent<HTMLInputElement>) => {
      const changedValue = e.target.name as keyof typeof formik.values;
      if (e.target.value && !formik.touched[changedValue]) {
        formik.setFieldTouched(changedValue, true);
      }
      formik.handleChange(e);
    };

    const handleBlurFormik = (e: React.ChangeEvent<HTMLInputElement>) => {
      const touchedValue = e.target.name as keyof typeof formik.values;
      if (!formik.touched[touchedValue]) {
        formik.setFieldTouched(touchedValue, false);
      }
      return;
    };
    const isDisabledSaveButton =
      !!Object.keys(formik.errors).length ||
      !formik.values.firstName ||
      !formik.values.lastName ||
      !formik.values.address ||
      !formik.values.city ||
      !formik.values.postalCode;

    const errorExpirationDate =
      (formik.touched.month && formik.errors.month) || (formik.touched.year && formik.errors.year);
    const isDisabledButton = !!isDisabledSaveButton || (countriesAlpha.length ? !formik.values.state : false);
    useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          !isDisabledButton && formik.handleSubmit();
        }
      };
      document.addEventListener('keypress', handleKeyPress);
      return () => {
        document.removeEventListener('keypress', handleKeyPress);
      };
    }, [isDisabledSaveButton, countriesAlpha.length]);

    if (errorRequest) {
      return <Alert danger>{<span dangerouslySetInnerHTML={{ __html: errorRequest }} />}</Alert>;
    }
    return (
      <FormikProvider value={formik}>
        {isLoading && <Spinner />}
        {isFetching && <SuccessAddCard isFetching={isFetching} />}
        <div
          className={clsx(
            styles['recurly-form'],
            !isLoading && styles['recurly-form-ready'],
            isFetching && styles['recurly-form-hide'],
          )}
        >
          <form onSubmit={formik.handleSubmit} ref={formRef}>
            <input type="hidden" name="recurly-token" data-recurly="token" />
            <div className={styles['recurly-form-card']}>
              <legend className={styles['legend']}>Card</legend>
              <div
                className={clsx(
                  styles['recurly-field'],
                  formik.touched.cardNumber && formik.errors.cardNumber && styles['recurly-field-error'],
                )}
              >
                <label className={styles['label']}>Card number</label>
                <CardNumberElement
                  className={styles['recurly-element-block']}
                  onChange={handleChangeNumber}
                  style={{
                    fontSize: '16px',
                    fontColor: '#fff',
                    fontFamily: 'Poppins, sans-serif',
                    placeholder: { content: '', color: 'white' },
                  }}
                />
                {formik.touched.cardNumber && formik.errors.cardNumber && (
                  <Alert className="form-recurly-error" danger>
                    {formik.errors.cardNumber}
                  </Alert>
                )}
              </div>

              <div className={clsx(styles['recurly-fields-flex'])}>
                <div className={clsx(styles['recurly-field'], errorExpirationDate && styles['recurly-field-error'])}>
                  <label className={styles['label']}>Expiration date</label>
                  <div className={clsx(styles['recurly-fields-flex'], styles['recurly-fields-flex-date'])}>
                    <CardMonthElement
                      className={clsx(styles['recurly-element-block'], styles['recurly-element-block-month'])}
                      onChange={handleChangeMonth}
                      style={{
                        fontSize: '16px',
                        fontColor: '#fff',
                        fontFamily: 'Poppins, sans-serif ',
                        placeholder: { content: '', color: 'white' },
                      }}
                    />
                    <CardYearElement
                      className={clsx(styles['recurly-element-block'], styles['recurly-element-block-center'])}
                      onChange={handleChangeYear}
                      format={true}
                      style={{
                        fontSize: '16px',
                        fontColor: '#fff',
                        fontFamily: 'Poppins, sans-serif ',
                        placeholder: { content: '', color: 'white' },
                      }}
                    />
                  </div>
                  {errorExpirationDate && (
                    <Alert className="form-recurly-error" danger>
                      {formik.errors.month || formik.errors.year}
                    </Alert>
                  )}
                </div>
                <div
                  className={clsx(
                    styles['recurly-field'],
                    formik.touched.cvc && formik.errors.cvc && styles['recurly-field-error'],
                  )}
                >
                  <label className={styles['label']}>CVC</label>

                  <CardCvvElement
                    className={styles['recurly-element-block']}
                    onChange={handleChangeCVC}
                    onReady={handleReady}
                    style={{
                      fontSize: '16px',
                      fontColor: '#fff',
                      fontFamily: 'Poppins, sans-serif ',
                      placeholder: { content: '', color: 'white' },
                    }}
                  />
                  {formik.touched.cvc && formik.errors.cvc && (
                    <Alert className="form-recurly-error" danger>
                      {formik.errors.cvc}
                    </Alert>
                  )}
                </div>
              </div>

              <div className={styles['recurly-fields-flex']}>
                <div className={styles['field-first-name']}>
                  <label className={styles['label']}>First name</label>
                  <Input
                    name="firstName"
                    data-recurly="first_name"
                    onChange={handleChangeFormik}
                    onBlur={handleBlurFormik}
                  />
                </div>
                <div className={styles['field-last-name']}>
                  <label className={styles['label']}>Last name</label>
                  <Input
                    name="lastName"
                    data-recurly="last_name"
                    onChange={handleChangeFormik}
                    onBlur={handleBlurFormik}
                  />
                </div>
              </div>
            </div>
            <div>
              <legend className={styles['legend']}>Billing address</legend>
              <div className={styles['recurly-field']}>
                <label className={styles['label']}>Address</label>
                <Input name="address" data-recurly="address1" onChange={handleChangeFormik} onBlur={handleBlurFormik} />
              </div>
              <div className={styles['recurly-fields-flex']}>
                <div className={clsx(styles['field-zip-code'], styles['recurly-field'])}>
                  <label className={styles['label']}>Zip code</label>
                  <Input
                    name="postalCode"
                    data-recurly="postal_code"
                    onChange={handleChangeFormik}
                    onBlur={handleBlurFormik}
                  />
                </div>
                <div className={clsx(styles['field-city'], styles['recurly-field'])}>
                  <label className={styles['label']}>City</label>
                  <Input name="city" data-recurly="city" onChange={handleChangeFormik} onBlur={handleBlurFormik} />
                </div>
              </div>
              {!!countriesAlpha.length && (
                <div>
                  <div className={clsx(styles['field-country'], styles['recurly-field'])}>
                    <label className={styles['label']}>State</label>

                    <Select
                      name="state"
                      isValidateErrors={false}
                      data-recurly="state"
                      value={formik.values.state}
                      options={
                        formik.values.state
                          ? statesRange(countriesAlpha)
                          : [emptyOptions, ...statesRange(countriesAlpha)]
                      }
                    />
                  </div>
                </div>
              )}
              <div>
                <div className={clsx(styles['field-country'], styles['recurly-field'])}>
                  <label className={styles['label']}>Country</label>

                  <Select
                    name="country"
                    isValidateErrors={false}
                    data-recurly="country"
                    value={
                      formik.values.country ||
                      transformCountryToCountryAlpha({
                        allCountries: allCountries,
                        countryId: `${userController.userCountry.id}`,
                      })
                    }
                    options={transformCountriesRangeWIthAlpha(allCountries)}
                  />
                </div>
              </div>
            </div>

            <div className={styles['form-buttons']}>
              <Button xl onClick={handleBack}>
                Go back
              </Button>
              <Button
                type="submit"
                primary
                lg
                className={styles['button-save']}
                disabled={isDisabledButton}
                loading={isFetching}
              >
                {isCheckoutPage ? 'Confirm payment' : 'Save changes'}
              </Button>
            </div>
          </form>
        </div>
      </FormikProvider>
    );
  },
);
export default RecurlyCreditCard;
