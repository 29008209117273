import { action, computed, makeObservable, observable } from 'mobx';
import AnalyticService from 'services/analyticsService/analyticsService';
import BoxOficceService from 'services/boxOficceService/boxOficceService';
import CartService from 'services/cartService/cartService';
import InitPagesService from 'services/initPagesService/initPagesService';
import MiscService from 'services/miscService/miskService';
import PageService from 'services/pageService/pageService';
import PairingService from 'services/pairingService/pairingService';
import SubscriptionService from 'services/subscriptionService/subscriptionService';
import UserService from 'services/userService/userService';

import { IErrorOverrides } from 'types/errors';
import { ICountry } from 'types/localizations';
import { Messages } from 'types/messages';
import { IOrdersResponse } from 'types/orders';
import { IReauthModalContent } from 'types/shared';
import { ISubscriptionByUsableSubscription, ISubscriptionsResponseWithOrders } from 'types/subscriptions';
import { ICountryByUser } from 'types/users';

import {
  filterActiveSubscriptions,
  bestSubscriptionsForCloud,
  bestSubscriptionsWithoutCloud,
} from 'utils/subscriptionFilters';
import { translateErrors } from 'utils/translateErrors';

import { USER_CLOUD_GROUP_NAME } from '../constants';
import { Routes } from '../routes';

export default class SelectPlanController extends InitPagesService {
  public constructor(
    private readonly _miscService: MiscService,
    private readonly _userService: UserService,
    private readonly _pairingService: PairingService,
    private readonly _boxOfficeService: BoxOficceService,
    private readonly _subscriptionService: SubscriptionService,
    private readonly _cartService: CartService,
    private readonly _pageService: PageService,
    private readonly _analyticService: AnalyticService,
  ) {
    super();
    makeObservable(this);
  }

  @observable
  private _subscriptionPlans: ISubscriptionByUsableSubscription[] = [];

  @observable
  private _isSwitching = false;

  @observable
  private _userAccountSubscriptions: ISubscriptionsResponseWithOrders[] = [];

  @observable
  private _userCanStartTrial = false;

  @observable
  private _hasSubscription = false;

  @observable
  private _currentSubscription: ISubscriptionsResponseWithOrders | null = null;

  @observable
  private _currentSubscriptionToRender: ISubscriptionByUsableSubscription | null = null;

  @observable
  private _selectedPlan: ISubscriptionByUsableSubscription | null = null;

  @observable
  private _numberOfDisplayedItems = 0;

  @observable
  private _showRecommendedOnly = true;

  @observable
  private _numberOfRecommendedItems = 0;

  @observable
  private _hasUnrecommendedItems = false;

  @observable
  private _assumedCountryBasedOnIP: ICountry | null = null;

  @observable
  private _error = '';

  @observable
  private _assumedCountry: ICountryByUser | null = null;

  @observable
  private _errorOverrides: IErrorOverrides = {
    CANNOT_CHANGE_TO_OR_FROM_FREE_PLAN: '',
    ACCOUNT_HAS_NON_RECURRING_SUBSCRIPTION: '',
  };

  @action
  public setErrorOverrides(errors: IErrorOverrides) {
    this._errorOverrides = errors;
  }

  @computed
  public get errorOverrides() {
    return this._errorOverrides;
  }

  @action
  public setAssumedCountry(country: ICountryByUser | null) {
    this._assumedCountry = country;
  }

  @computed
  public get assumedCountry() {
    return this._assumedCountry;
  }

  @action
  public setError(error: string) {
    this._error = error;
  }

  @computed
  public get error() {
    return this._error;
  }

  @action setAssumedCountryBasedOnIp(countryByIp: ICountry) {
    this._assumedCountryBasedOnIP = countryByIp;
  }

  @computed get assumedCountryBasedOnIP() {
    return this._assumedCountryBasedOnIP;
  }

  @action
  public setHasUnrecommendedItems(isHasUnRecommended: boolean) {
    this._hasUnrecommendedItems = isHasUnRecommended;
  }

  @computed
  public get hasUnrecommendedItems() {
    return this._hasUnrecommendedItems;
  }

  @action
  public setNumberOfRecommendedItems(recommendedItems: number) {
    this._numberOfRecommendedItems = recommendedItems;
  }

  @computed
  public get numberOfRecommendedItems() {
    return this._numberOfRecommendedItems;
  }

  @action
  public setShowRecommendedOnly = (isShowRecommendedOnly: boolean) => {
    this._showRecommendedOnly = isShowRecommendedOnly;
  };

  @computed
  public get showRecommendedOnly() {
    return this._showRecommendedOnly;
  }

  @action
  public setNumberOfDisplayedItems(displayedItems: number) {
    this._numberOfDisplayedItems = displayedItems;
  }

  @computed
  public get numberOfDisplayedItems() {
    return this._numberOfDisplayedItems;
  }

  @action
  public setSelectedPlan(plan: ISubscriptionByUsableSubscription | null) {
    this._selectedPlan = plan;
  }
  @computed
  public get selectedPlan() {
    return this._selectedPlan;
  }

  @action setCurrentSubscription(currentSubscription: ISubscriptionsResponseWithOrders | null) {
    this._currentSubscription = currentSubscription;
  }

  @computed get currentSubscription(): ISubscriptionsResponseWithOrders | null {
    return this._currentSubscription;
  }

  @action
  public setSubscriptionPlans(subscriptionPlans: ISubscriptionByUsableSubscription[]) {
    this._subscriptionPlans = subscriptionPlans;
  }

  @computed
  public get subscriptionPlans(): ISubscriptionByUsableSubscription[] {
    return this._subscriptionPlans;
  }

  @action
  public setCurrentSubscriptionToRender(subscriptionToRender: ISubscriptionByUsableSubscription | null) {
    this._currentSubscriptionToRender = subscriptionToRender;
  }

  @computed get currentSubscriptionToRender() {
    return this._currentSubscriptionToRender;
  }

  @action setHasSubscription(hasSubscription: boolean) {
    this._hasSubscription = hasSubscription;
  }

  @computed get hasSubscription() {
    return this._hasSubscription;
  }

  @action
  public setUserCanStartTrial(isCanTrial: boolean) {
    this._userCanStartTrial = isCanTrial;
  }

  @computed
  public get userCanStartTrial() {
    return this._userCanStartTrial;
  }

  @action
  public setUserAccountSubscriptions(accountSubscriptions: ISubscriptionsResponseWithOrders[]) {
    this._userAccountSubscriptions = accountSubscriptions;
  }

  @computed
  public get userAccountSubscriptions() {
    return this._userAccountSubscriptions;
  }

  @action
  public setIsSwitching(isSwitching: boolean) {
    this._isSwitching = isSwitching;
  }

  @computed
  public get isSwitching() {
    return this._isSwitching;
  }

  @observable
  private _reauthModalIsOpen = false;

  @action
  public setIsOpenReauyhModal(isOpenReauth: boolean) {
    this._reauthModalIsOpen = isOpenReauth;
  }

  @computed
  public get reauthModalIsOpen() {
    return this._reauthModalIsOpen;
  }

  @observable
  private _reauthModaContent: IReauthModalContent | null = null;

  @action
  public setReauthModaContent(modalContent: IReauthModalContent | null) {
    this._reauthModaContent = modalContent;
  }

  @computed
  public get reauthModaContent() {
    return this._reauthModaContent;
  }

  public async setStatesAfterInitPages() {
    this.setSelectedPlan(null);

    if (this.subscriptionPlans.length > 2 && !this.hasSubscription) {
      const selectedPlan = this.subscriptionPlans.find((p) => p.id === 105);
      this.setSelectedPlan(selectedPlan || null);
    }
    this.calculateVisiblePlanCount();
    const unRecommendedPlans = this.subscriptionPlans.filter((plan) => !plan._isRecommended);
    this.setHasUnrecommendedItems(!!unRecommendedPlans.length);
  }

  @action
  public calculateVisiblePlanCount() {
    const startCalculate = this.isSwitching ? 1 : 0;
    const displayedItems = this.subscriptionPlans.reduce((accumulator, plan) => {
      const countThisPlan = !this.showRecommendedOnly || plan._isRecommended;
      return accumulator + (countThisPlan ? 1 : 0);
    }, startCalculate);
    this.setNumberOfDisplayedItems(displayedItems);
    const recommendedItems = this.subscriptionPlans.filter((plan) => plan._isRecommended).length;
    this.setNumberOfRecommendedItems(recommendedItems);
  }

  public async initForm() {
    this.clearState();
    try {
      await this._miscService.setUserIsInSupportedCloudCountry();
      await this._userService.fetchAccountSubscriptions();
      const isSwitching = await this._boxOfficeService.canSwitchToUtomikSubscription();
      const subscriptionPlans = await this._subscriptionService.getSubscriptionPlans();
      await this._miscService.getApiHasCloudSupport();
      await this._pairingService.isCloudRegistrationAllowed();
      await this._userService.fetchLoggedInAccount();
      const userAccountSubscriptions = await this._userService.getAccountSubscriptions();
      const isCanStartTrial = this._userService.getCanStartTrial();
      const userCountryByIp = await this._miscService.getUserCountry();
      this.setIsSwitching(isSwitching);
      this.setUserAccountSubscriptions(userAccountSubscriptions);
      this.setUserCanStartTrial(isCanStartTrial);
      this.setHasSubscription(!!userAccountSubscriptions.length);
      this.setCurrentSubscription(this.getActiveAccountSubscription(userAccountSubscriptions));
      const filteredSubscriptions = this.setFilteredSubscriptionPlans(subscriptionPlans);
      const allSubscriptions = this._currentSubscriptionToRender
        ? [this._currentSubscriptionToRender, ...filteredSubscriptions]
        : filteredSubscriptions;
      const allSubscriptionsSorted = allSubscriptions.sort((planA, planB) => {
        // First, sort by the _isRecommended flag
        if (planA._isRecommended && !planB._isRecommended) {
          return -1; // planA should come before planB
        } else if (!planA._isRecommended && planB._isRecommended) {
          return 1; // planB should come before planA
        }

        // If both are equally recommended or not, sort by amount_in_cents
        return planA._totalPricing.amount_in_cents - planB._totalPricing.amount_in_cents;
      });
      this.setSubscriptionPlans(allSubscriptionsSorted);
      this.setAssumedCountryBasedOnIp(userCountryByIp);
      this.setAssumedCountry(this.user.country ? null : userCountryByIp);
      this.filterPurchaseableSubscription();
      await this.setStatesAfterInitPages();
    } finally {
      this.setIsLoading(false);
    }
  }

  public async initPage() {
    await this._userService.fetchUser();
  }

  public toggleAllPlans = () => {
    this.setShowRecommendedOnly(!this.showRecommendedOnly);
    this.calculateVisiblePlanCount();
  };

  public get userHasCloudSupport() {
    return this._userService.isInSupportedCloudCountry;
  }

  public get utomikIsLiveInUsersCountry() {
    return this._userService.utomikIsLiveInUsersCountry;
  }

  public get userCountry() {
    return this._userService.userCountry;
  }

  public get isCloudRegistrationAllowed() {
    return this._userService.isCloudRegistrationAllowed;
  }

  public get isApiHasCloudSupport() {
    return this._userService.isApiHasCloudSupport;
  }

  public get isInSupportedCloudCountry() {
    return this._userService.isInSupportedCloudCountry;
  }

  public get user() {
    return this._userService.user;
  }

  public get isInTVFlow(): boolean {
    return this._pairingService.isPairingCodePending();
  }

  public get isCloudUser() {
    return this.user.groups.includes(USER_CLOUD_GROUP_NAME);
  }

  public get cloudRegistrationsBlocked() {
    return !this.isCloudRegistrationAllowed;
  }

  public getActiveAccountSubscription(
    userAccountSubscriptions: ISubscriptionsResponseWithOrders[],
  ): ISubscriptionsResponseWithOrders {
    const accSubscription = filterActiveSubscriptions(userAccountSubscriptions);
    return accSubscription[0] || null;
  }

  public setFilteredSubscriptionPlans(
    subscriptionPlans: ISubscriptionByUsableSubscription[],
  ): ISubscriptionByUsableSubscription[] {
    const currentSubscription = this.currentSubscription;
    let copySubscriptionPlans = [...subscriptionPlans];
    const isCurrentSubscription =
      currentSubscription &&
      currentSubscription.order &&
      (currentSubscription.order as IOrdersResponse).order_shop_items;
    const isCloudSupportPlans =
      this.isApiHasCloudSupport && this.userHasCloudSupport && this.isCloudRegistrationAllowed;
    if (isCurrentSubscription) {
      const currentSubAsShopItem = (currentSubscription.order as IOrdersResponse).order_shop_items.find((item) => {
        return item.shop_item.url.includes('subscriptionplans');
      });
      const subscriptionPlansWitOutShopItem = subscriptionPlans.filter((subscriptionPlan) => {
        return currentSubAsShopItem && currentSubAsShopItem.shop_item.id !== subscriptionPlan.id;
      });
      const subscriptionToRender = subscriptionPlans.filter((subscriptionPlan) => {
        return currentSubAsShopItem && currentSubAsShopItem.shop_item.id === subscriptionPlan.id;
      });
      if (subscriptionToRender.length) {
        this.setCurrentSubscriptionToRender(subscriptionToRender[0]);
      } else {
        const transformSubscriptionToRender = {
          ...currentSubscription,

          period: (currentSubscription.order as IOrdersResponse).sub_period,
          period_length: (currentSubscription.order as IOrdersResponse).sub_period_length,
        };
        this.setCurrentSubscriptionToRender(transformSubscriptionToRender as any);
      }

      copySubscriptionPlans = subscriptionPlansWitOutShopItem;
    }
    if (isCloudSupportPlans) {
      return bestSubscriptionsForCloud(copySubscriptionPlans, this.isInTVFlow);
    }
    return bestSubscriptionsWithoutCloud({
      subscriptions: copySubscriptionPlans,
      isInTVFlow: this.isInTVFlow,
      isSwitching: this.isSwitching,
    });
  }

  public filterPurchaseableSubscription() {
    const purchasableSubscription = this.subscriptionPlans.filter((subscription) =>
      this._subscriptionService.getCanPurchase(subscription),
    );
    if (purchasableSubscription.length) return;
    if (this.currentSubscription && !this.currentSubscription.recurring) {
      const nonRecurringOverride = this.isInTVFlow
        ? Messages['CANNOT_CHANGE_FROM_NONRECURRING_PLAN_TO_CLOUD']
        : Messages['CANNOT_CHANGE_FROM_NONRECURRING_PLAN'];

      this.setErrorOverrides({
        ACCOUNT_HAS_NON_RECURRING_SUBSCRIPTION: nonRecurringOverride,
        CANNOT_CHANGE_TO_OR_FROM_FREE_PLAN: nonRecurringOverride,
      });
    }
    let errorMessages: string[] = this.subscriptionPlans.reduce<string[]>((m, plan) => {
      if (plan.can_purchase && !plan.can_purchase.allow) {
        if (plan.can_purchase.message) {
          m.push(plan.can_purchase.message);
        }
      }
      return m;
    }, []);

    errorMessages = [...new Set(errorMessages)];
    if (errorMessages.length === 0) {
      throw new Error('COULD_NOT_FETCH_AVAILABLE_SUBSCRIPTIONS');
    }
    throw new Error(errorMessages[0]);
  }

  public async handleWarningsAndProceed() {
    let isDestructiveAction = false;
    const isInadvisableCloudAction = this.isInTVFlow && this.selectedPlan?._isCloudSubscription;
    if (this.isSwitching && this.currentSubscription && this.selectedPlan) {
      const isSwitchingFromFamily = this.currentSubscription && this.currentSubscription.type === 'SF';
      const isSwitchingToPersonal = this.selectedPlan && this.selectedPlan.type === 'SP';
      isDestructiveAction = Boolean(isSwitchingFromFamily && isSwitchingToPersonal);
    }

    if (isDestructiveAction) {
      this.setReauthModaContent({
        title: 'Warning',
        message: [
          'You are about to change to a personal plan. Any members in your current family plan will lose access to Utomik.',
        ],
        buttonLabel: 'Yes, I am sure',
        buttonIsDanger: isDestructiveAction || isInadvisableCloudAction,
      });
      this.setIsOpenReauyhModal(true);
    } else {
      await this.ensureProductInCart();
    }
  }

  public ensureProductInCart = async () => {
    if (this.selectedPlan === null) return;
    try {
      this._cartService.emptyCart();
      const subscriptionURL = 'shop/subscriptionplans/' + this.selectedPlan.id;
      this._cartService.setProduct(subscriptionURL);
      const nextURL = this.isInTVFlow ? Routes.SuccessTv : Routes.Success;
      this._cartService.setNext(nextURL);
      this._cartService.setReturnProduct(this.currentSubscription);
      const metadata = {
        firstTimeSubscriber: this.userAccountSubscriptions && this.userAccountSubscriptions.length === 0,
        canStartTrial: this.userCanStartTrial,
      };

      this._cartService.setMeta(metadata);
      this._analyticService.handleAddToCart({
        product: this.selectedPlan,
        canStartTrial: this.userCanStartTrial,
        promo: this.selectedPlan?.ref,
      });
      const currentPath = this._pageService.getPath();

      const nextPath = (currentPath.indexOf('/register/') > -1 ? '/register' : '') + '/checkout';
      this._pageService.setLocation(nextPath);
    } catch (e: any) {
      this._analyticService.handleAddToCartError();
      this.setErrorRequest('Something went wrong adding subscription to the cart');
      throw new Error(e);
    }
    await this.onConfirmSelectedPlan();
  };

  public async onConfirmSelectedPlan() {
    if (!this.user.country && this.assumedCountryBasedOnIP?.id) {
      const patchData = {
        country: this.assumedCountryBasedOnIP.id,
      };

      try {
        await this._userService.patchUserCountry(patchData);
      } catch (e) {
        throw new Error('COULD_NOT_FIND_COUNTRY');
      }
    }
  }

  public async confirmSelectedPlan() {
    await this.handleWarningsAndProceed();
  }

  public setErrorRequest = (error: string) => {
    if (this.user && this.user.deletion_date) {
      return;
    }
    this.setError(translateErrors(error));
  };

  public clearState() {
    this._subscriptionService.setAllSubscriptionPlans([]);
    this._userService.setAccountSubscriptionDefault([]);
    this._userService.setAccountSubscription([]);
    this.setUserAccountSubscriptions([]);
    this.setCurrentSubscription(null);
    this.setErrorRequest('');
    this.setError('');
    this.setIsLoading(true);
    this.setErrorOverrides({
      ACCOUNT_HAS_NON_RECURRING_SUBSCRIPTION: '',
      CANNOT_CHANGE_TO_OR_FROM_FREE_PLAN: '',
    });
    this.setIsSwitching(false);
    this.setUserAccountSubscriptions([]);
    this.setSubscriptionPlans([]);
    this.setNumberOfDisplayedItems(0);
    this.setHasUnrecommendedItems(false);
    this.setShowRecommendedOnly(true);
    this.setCurrentSubscriptionToRender(null);
    this.setNumberOfRecommendedItems(0);
  }

  public toggleModalReauth = () => {
    this.setIsOpenReauyhModal(!this.reauthModalIsOpen);
  };
}
